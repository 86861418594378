//exports.onInitialClientRender = (_, { clientId }) => {
exports.onClientEntry = (_, { clientId }) => {
    const preconnectURLs = [
        //['//ofgogoatan.com', 'afu.php?zoneid=3282445'],
        ['//resources.infolinks.com', 'js/infolinks_main.js']
    ];

    preconnectURLs.forEach(url => {
        let preconnect = document.createElement('link');
        preconnect.rel = 'preconnect';
        preconnect.href = url[0];
        document.head.appendChild(preconnect);

        let prefetch = document.createElement('link');
        prefetch.rel = 'dns-prefetch';
        prefetch.href = url[0];
        document.head.appendChild(prefetch);

        const script = document.createElement('script');
        script.src = `${url[0]}/${url[1]}`;
        script.defer = true;
        document.head.appendChild(script);
    });
}

// exports.onClientEntry = (_, { clientId }) => {  
//   const domain = 'https://pagead2.googlesyndication.com';
//   const preconnectURLs = [
//     domain,
//     'https://adservice.google.com',
//     'https://tpc.googlesyndication.com'
//   ]

//   preconnectURLs.forEach( url => {
//     /*let preconnect = document.createElement('link');
//     preconnect.rel = 'preconnect';
//     preconnect.href = url;
//     document.head.appendChild(preconnect);*/

//     let prefetch = document.createElement('link');
//     prefetch.rel = 'dns-prefetch';
//     prefetch.href = url;
//     document.head.appendChild(prefetch);
//   });

//   const script = document.createElement('script');
//   script.src = `${domain}/pagead/js/adsbygoogle.js`;
//   script.defer = true;
//   script.setAttribute('data-ad-client', clientId);
//   document.head.appendChild(script);
// }