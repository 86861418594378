module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"meparto.club","short_name":"meparto.club","description":"Página web de humor, chistes, memes, gifs graciosos, monólogos y mucho más contenido para pasar un rato agradable.","lang":"es","display":"minimal-ui","start_url":"/","background_color":"#f9fbfd","theme_color":"#fff","icon":"src/images/meparto.png"},
    },{
      plugin: require('../plugins/gatsby-theme-adsense/gatsby-browser.js'),
      options: {"plugins":[],"clientId":"ca-pub-8835512484337367"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-635692-15"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
